import React from 'react';
import {Provider} from "react-redux";
import ReactDOM from 'react-dom/client';
import Routes from './routes';
import store from "./store";
import reportWebVitals from './reportWebVitals';
import {ErrorBoundary} from './utils/bugsnag';
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);


root.render(
    <ErrorBoundary>
        <Provider store={store}>
            <Routes/>
        </Provider>
    </ErrorBoundary>
);

reportWebVitals();
