import React, {lazy, Suspense} from 'react';
import {Auth0Provider} from '@auth0/auth0-react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import PrivateOutlet from "./PrivateOutlet";
import PrivateVendorOutlet from "./PrivateVendorOutlet";


const AfterLoginCallbackPage = lazy(() => import('src/pages/_afterLogin'))

const AccessDenied = lazy(() => import('src/pages/unauthorized/AccessDenied'));
const Page404 = lazy(() => import('src/pages/unauthorized/404'));
const ThankYou = lazy(() => import('src/pages/unauthorized/ThankYou'));
const DrataSuccess = lazy(() => import('src/pages/unauthorized/Drata/Success'));

const FormEditPage = lazy(() => import('src/pages/forms/EditableForm'));
const DealregFormEditPage = lazy(() => import('src/pages/dealreg/DealregEditableForm'));
const FormResponsesPage = lazy(() => import('src/pages/forms/Responses'));
const FormResponsePage =  lazy(() => import('src/pages/forms/ResponsePage'));
const FormListPage = lazy(() => import('src/pages/forms/List'));

const PublicFormPage = lazy(() => import('src/pages/public/Form'));
const DealregFormPreview = lazy(() => import('src/pages/dealreg/FormPreview'))
const DealregFormSubmissions = lazy(() => import('src/pages/dealreg/Submissions'))
const DealregFormSubmissionPage = lazy(() => import('src/pages/dealreg/SubmissionPage'))

export default function MainRouter() {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";

    return <Suspense>
        <BrowserRouter>
            <Auth0Provider domain={domain}
                           clientId={clientId}
                           redirectUri={`${document.location.origin}/init?returnTo=${document.location.pathname}`}
                           audience={audience}
                           scope={'openid profile email'}
                           useRefreshTokens={true}
                           cacheLocation="localstorage"
            >
                <Routes>
                    <Route path="/access-denied" element={<AccessDenied/>}/>
                    <Route path="/success" element={<ThankYou/>}/>
                    <Route path="/drata/success" element={<DrataSuccess/>}/>
                    <Route path="/forms/:id" element={<PublicFormPage/>}/>

                    <Route element={<PrivateOutlet/>}>
                        <Route path="/" element={<Navigate to="/forms"/>}/>
                        <Route path="/init" element={<AfterLoginCallbackPage/>}/>
                        <Route path="/dealreg/forms/:id" element={<DealregFormPreview/>}/>
                    </Route>

                    {/** URLs allowed only for vendors */}
                    <Route element={<PrivateVendorOutlet/>}>
                        <Route path="/forms" element={<FormListPage/>}/>
                        <Route path="/forms/edit/:id" element={<FormEditPage/>}/>
                        <Route path="/forms/edit/:id/responses" element={<FormResponsesPage/>}/>
                        <Route path="/forms/edit/:id/responses/:rid" element={<FormResponsePage/>}/>
                        <Route path="/dealreg/forms/edit/:id" element={<DealregFormEditPage/>}/>
                        <Route path="/dealreg/forms/edit/:id/submissions" element={<DealregFormSubmissions/>}/>
                        <Route path="/dealreg/forms/edit/:id/submissions/:sid" element={<DealregFormSubmissionPage/>}/>
                    </Route>

                    <Route path='*' element={<Page404/>}/>
                </Routes>
            </Auth0Provider>
        </BrowserRouter>
    </Suspense>
};