import React from 'react';
import config from 'src/config';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
    appType: 'dashboard',
    releaseStage: process.env.NODE_ENV || 'development',
    enabledReleaseStages: ['production', 'development'],
    apiKey: config.bugsnagApiKey,
    plugins: [new BugsnagPluginReact()]
});

const reactPlugin = Bugsnag.getPlugin('react');

let ReactPlugin: any = ({children}: { children: any }) => children;
if (reactPlugin) ReactPlugin = reactPlugin.createErrorBoundary(React);
export const ErrorBoundary = ReactPlugin;

export default Bugsnag;