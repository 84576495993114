import thunk from 'redux-thunk';
import {combineReducers, createStore, applyMiddleware} from 'redux';
import formsReducer from "./forms"
import userReducer from './user'

const rootReducer = combineReducers({
    user: userReducer,
    forms: formsReducer
});

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
    const {logger} = require(`redux-logger`);
    middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export default store;
