import {FormsActions, InitialFormData} from "./interface";
import {FormDataType, FormField} from "src/types";

const initialState: {
    list: FormDataType[],
    editableForm: FormDataType | any
    editableFormResponses: any[]
    editableFormSubmissions: any[]
    submissionsCount: number
    submissionItem: any
    responsesCount: number
    responseItem: any
} = {
    list: [],
    editableForm: Object.assign({}, InitialFormData),
    editableFormResponses: [],
    editableFormSubmissions: [],
    submissionItem: null,
    responseItem: null,
    submissionsCount: 0,
    responsesCount: 0
};

export default function (state = initialState, {type, payload}: { type: string, payload: any }) {
    switch (type) {
        case FormsActions.FETCH_FORM_LIST_SUCCESS:
            return Object.assign({}, state, {
                list: payload.list
            });
        case FormsActions.FETCH_FORM_ITEM_SUCCESS:
            return Object.assign({}, state, {
                editableForm: Object.assign({}, InitialFormData, payload.item)
            });
        case FormsActions.FETCH_FORM_ITEM_RESPONSES_SUCCESS:
            return Object.assign({}, state, {
                editableFormResponses: payload.data.items || [],
                responsesCount: payload.data.count
            });
        case FormsActions.FETCH_FORM_ITEM_SUBMISSIONS_SUCCESS:
            return Object.assign({}, state, {
                editableFormSubmissions: payload.data.items || [],
                submissionsCount: payload.data.count
            });
        case FormsActions.CREATE_FORM_SUCCESS:
            return Object.assign({}, state, {
                list: state.list.concat([payload.item]),
                editableForm: Object.assign({}, InitialFormData, payload.item)
            });

        case FormsActions.DUPLICATE_FORM_SUCCESS:
            return Object.assign({}, state, {
                list: state.list.concat([payload.item]),
                editableForm: Object.assign({}, payload.item)
            });
        case FormsActions.UPDATE_FORM_SUCCESS:
            return Object.assign({}, state, {
                list: state.list.map((entry) => {
                    if (payload.formData.id === entry.id) return payload.formData;
                    return entry;
                }),
                editableForm: Object.assign({}, payload.formData)
            });
        case FormsActions.UPDATE_FORM_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                editableForm: Object.assign({}, state.editableForm, {
                    settings: payload.settings
                })
            });
        case FormsActions.ADD_FORM_FIELD_SUCCESS:
            return Object.assign({}, state, {
                editableForm: Object.assign({}, state.editableForm, {
                    fields: [...state.editableForm.fields].concat([payload.field])
                })
            });

        case FormsActions.UPDATE_FORM_FIELD_SUCCESS:
            return Object.assign({}, state, {
                editableForm: Object.assign({}, state.editableForm, {
                    fields: state.editableForm.fields.map((field: FormField) => {
                        if (field.id === payload.id) {
                            return Object.assign({}, field, payload.field);
                        } else {
                            return field;
                        }
                    })
                })
            });

        case FormsActions.UPLOAD_FORM_LOGO_SUCCESS:
            return Object.assign({}, state, {
                editableForm: Object.assign({}, state.editableForm, {
                    logoUrl: payload.logoUrl
                })
            });

        case FormsActions.DELETE_FORM_LOGO_SUCCESS:
            return Object.assign({}, state, {
                editableForm: Object.assign({}, state.editableForm, {
                    logoUrl: null
                })
            });

        case FormsActions.UPDATE_FORM_FIELD_ORDER_SUCCESS:
            return Object.assign({}, state, {
                editableForm: Object.assign({}, state.editableForm, {
                    fields: state.editableForm.fields.map((field: FormField) => {
                        if (field.order === payload.nextOrder) {
                            return Object.assign({}, field, {order: payload.prevOrder});
                        }

                        if (field.id === payload.id) {
                            return Object.assign({}, field, {order: payload.nextOrder});
                        }

                        return field;
                    })
                })
            });

        case FormsActions.DELETE_FORM_FIELD_SUCCESS:
            return Object.assign({}, state, {
                editableForm: Object.assign({}, state.editableForm, {
                    fields: state.editableForm.fields
                        .filter((field: FormField) => field.id !== payload.id)
                        .map((field: FormField, index: number) => {
                            field.order = index + 1;
                            return field;
                        })
                })
            });
        case FormsActions.DELETE_FORM_SUCCESS:
            return Object.assign({}, state, {
                list: Object.assign([], state.list.filter(({id}) => id !== payload.id)),
                editableForm: Object.assign({}, InitialFormData)
            });

        case FormsActions.UPDATE_SUBMISSION_ITEM_STATUS_SUCCESS:
            return Object.assign({}, state, {
                submissionItem: Object.assign({}, state.submissionItem, {status: payload.status}),
                editableFormSubmissions: state.editableFormSubmissions.map((sub) => {
                    if (sub.id === payload.submissionId) {
                        return Object.assign({}, sub, {status: payload.status});
                    }

                    return sub;
                })
            });

        case FormsActions.FETCH_SUBMISSION_ITEM_SUCCESS:
            return Object.assign({}, state, {
                submissionItem: payload.data
            });

        case FormsActions.FETCH_RESPONSE_ITEM_SUCCESS:
            return Object.assign({}, state, {
                responseItem: payload.data
            });

        default:
            return state;
    }
};