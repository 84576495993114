import React from 'react';
import {Outlet} from 'react-router-dom';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import PagePreloader from "src/components/elements/PagePreloader";

export const AuthenticationGuard = () => {
    const Component = withAuthenticationRequired(Outlet, {
        onRedirecting: () => (
            <div className="page-layout">
                <PagePreloader/>
            </div>
        ),
    });

    return <Component/>;
};

export default AuthenticationGuard