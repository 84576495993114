const CONFIG = {
    appName: "apply",
    bugsnagApiKey: "e5b28b71004c3fb2655bf8cbdba6e2ea",
    captchaSiteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY as string,
    api: {
        users: process.env.REACT_APP_USERS_SERVICE as string,
        forms: process.env.REACT_APP_APPLY_FORMS_SERVICE as string
    }
};

export default CONFIG;