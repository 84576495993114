export type User = {
    id: string;
    email: string;
    vendorId: string;
    organizationName: string;
    firstName: string;
    lastName: string;
    permissions: string[]
}

export enum UserActions {
    FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"
}