import React from 'react';
import Cookies from "js-cookie";
import {Navigate, Outlet} from 'react-router-dom';
import {withAuthenticationRequired} from '@auth0/auth0-react';
import PagePreloader from "src/components/elements/PagePreloader";


export const AuthenticationVendorGuard = () => {
    const isAllowAccess = Cookies.get('pp_allow_access');

    const Component = withAuthenticationRequired(Outlet, {
        onRedirecting: () => (
            <div className="page-layout">
                <PagePreloader/>
            </div>
        )
    });

    if (isAllowAccess === undefined) return <Component/>;
    return (isAllowAccess === 'true') ? <Component/> : <Navigate to={"/access-denied"} replace/>;
};

export default AuthenticationVendorGuard;