import {FormDataType} from "src/types";

export enum FormsActions {
    FETCH_FORM_LIST_SUCCESS = "FETCH_FORM_LIST_SUCCESS",
    FETCH_FORM_ITEM_SUCCESS = "FETCH_FORM_ITEM_SUCCESS",
    FETCH_FORM_ITEM_RESPONSES_SUCCESS = "FETCH_FORM_ITEM_RESPONSES_SUCCESS",
    FETCH_FORM_ITEM_SUBMISSIONS_SUCCESS = 'FETCH_FORM_ITEM_SUBMISSIONS_SUCCESS',
    CREATE_FORM_SUCCESS = "CREATE_FORM_SUCCESS",
    DUPLICATE_FORM_SUCCESS = "DUPLICATE_FORM_SUCCESS",
    UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS",
    UPDATE_FORM_SETTINGS_SUCCESS = 'UPDATE_FORM_SETTINGS_SUCCESS',
    ADD_FORM_FIELD_SUCCESS = "ADD_FORM_FIELD_SUCCESS",
    UPDATE_FORM_FIELD_SUCCESS = "UPDATE_FORM_FIELD_SUCCESS",
    UPLOAD_FORM_LOGO_SUCCESS = "UPLOAD_FORM_LOGO_SUCCESS",
    UPDATE_FORM_FIELD_ORDER_SUCCESS = "UPDATE_FORM_FIELD_ORDER_SUCCESS",
    DELETE_FORM_FIELD_SUCCESS = "DELETE_FORM_FIELD_SUCCESS",
    DELETE_FORM_LOGO_SUCCESS = "DELETE_FORM_LOGO_SUCCESS",
    DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS",
    UPDATE_SUBMISSION_ITEM_STATUS_SUCCESS = "UPDATE_SUBMISSION_ITEM_STATUS_SUCCESS",
    FETCH_SUBMISSION_ITEM_SUCCESS = "FETCH_SUBMISSION_ITEM_SUCCESS",
    FETCH_RESPONSE_ITEM_SUCCESS = "FETCH_RESPONSE_ITEM_SUCCESS"
}

export const InitialFormData: FormDataType = {
    id: "",
    name: "",
    description: "",
    formType: "",
    createdAt: new Date(),
    responseCount: 0,
    submissionCount: 0,
    fields: [],
    settings: {
        logoAlignment: "left",
        titleAlignment: "left"
    }
};