import {User, UserActions} from "./interface";

const initialState: User = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    vendorId: "",
    organizationName: "",
    permissions: []
};

export default function (state = initialState, {type, payload}: { type: string, payload: any }) {
    switch (type) {
        case UserActions.FETCH_USER_SUCCESS:
            return Object.assign({}, state, payload.user);
        default:
            return state;
    }
}